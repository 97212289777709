.accordion {
  a {
    color: #dfbd85;
  }

  .content {
    min-height: calc(100vh - 150px);
    max-width: 500px;
    margin: 5rem auto 0 auto;
    padding: 0 1rem;
  }

  .app-description {
    text-align: center;
  }

  h3 {
    font-size: 1.75rem;
    margin: 0;
  }

  p {
    line-height: 1.75rem;
  }

  button {
    display: flex;
    align-items: center;
    background: transparent;
    border: 1px dashed rgba(255, 255, 255, 0.411);
    font-size: 1.2rem;
    width: 100%;
    text-align: left;
    color: #ffffff;
    font-weight: bold;
    padding: 1rem;
  }

  button.active {
    background-color: #0a6a98;
    color: #ffffff;
    border: #0a6a98 solid 1px;
  }

  button:hover {
    background-color: #0a6a98;
  }

  .title-wrapper {
    display: block;
    position: relative;
    width: 100%;
  }

  .minus {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
  }

  .plus {
    width: 14px;
    height: 14px;
    transition-timing-function: ease-in;
    transition: all 1s;
    position: absolute;
    right: 0;
  }

  .panel-close {
    background: #e0e0e0;
    box-sizing: border-box;
    opacity: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    transition-timing-function: ease-out;
    transition: all .7s;
  }

  .panel-open {
    overflow: hidden;
    background: white;
    border-bottom: 1px solid rgba(122, 122, 122, 0.479);
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    opacity: 1;
    width: 100%;
    height: auto;
    transition-timing-function: ease-in;
    transition: all 1s;
  }

  .panel p {
    margin: 0;
  }

  .footer {
    text-align: center;
    height: 50px;
  }
}
