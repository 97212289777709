.icon-md {
  width: 40px;
  height: 40px;
}
.modal-content {
  text-transform: uppercase;
}

.nav_banner {
  background: linear-gradient(
    90deg,
    rgba(199, 233, 3, 0.986),
    rgb(4, 184, 124),
    rgb(112, 156, 226)
  );
  a {
    color: black;
    background: white;
    padding: 0 1rem;
    margin-left: 5px;
    border-radius: 18px;
  }

  @media only screen and (max-width: 600px) {
    h4{
      font-size: 15px;

    }
  }
}
// Navi //
.nav_userinfo{
  color: rgb(245, 245, 245);
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-right: 1rem;
  padding-right: 1rem;
  border-right: 1px solid rgba(255, 255, 255, 0.555);
}

.navi {
  // margin-bottom: 70px;
  font-family: "Signika", Arial, sans-serif;
  .navbar {
    border-bottom: 1px solid rgba(83, 83, 83, 0.212);
  }
  .navbar-toggler-icon{
    color: black;
  }
  .dropdown-menu {
    background: white;
    padding: 1rem 0;
    border: none;
    box-shadow: rgba(143, 143, 143, 0.534) 1px 1px 4px 1px;
    padding: 0;
  }
  a.dropdown-item {
    color: rgb(39, 39, 39);
    font-size: 16px;
    padding: 0.5rem 1rem;
  }
  a.dropdown-item:hover {
    color: white;
  }
  button.dropdown-item:hover {
    color: white;
  }
  button.dropdown-toggle {
    font-size: 15px;
  }
  .nav-item .nav-link {
    padding: 0.45rem 0.75rem;
    color: rgb(39, 39, 39);
    font-size: 15px;
  }
}

// Landing //
.landing {
  #home {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60vh;
    background: linear-gradient(
        rgba(11, 78, 122, 0.5) 60%,
        rgba(0, 0, 0, 0.808)
      ),
      url("../../assets/illustrations/shipping-landing.png");
    background-size: contain;
  }
  @media only screen and (max-width: 600px) {
    .col-3{
      min-width: 100%;
    }
  }
  
  .home_buttons {
    display: flex;
    flex-direction: column;
  }

  .package_levels_container {
    position: relative;
    bottom: 4rem;

    .bg-white {
      box-shadow: rgba(0, 0, 0, 0.329) 0px 4px 10px 0px;
      border-radius: 10px;
      text-align: center;
      transition: 0.2s;
    }
    .bg-white:hover {
      box-shadow: rgba(0, 0, 0, 0.329) 0px 8px 10px 0px;
      transition: 0.2s;
    }
    img {
      width: 3.5rem;
      width: 3.5rem;
      object-fit: cover;
    }
  }
  .landing_station-box {
    display: flex;
    flex-direction: column;
    text-align: left;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    padding: 1rem;
    gap: 5px;
    background: white;
    a {
      color: white;
    }
  }

  .landing_stations_container {
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    row-gap: 1rem;
    column-gap: 5px;
  }

  #tariffs {
    background: linear-gradient(
      90deg,
      rgba(83, 144, 172, 0.986) 0%,
      50%,
      rgb(245, 245, 245) 100%
    );
    color: white;

    .tariffs_container {
      display: grid;
      font-size: 18px;
      grid-template-columns: 48% 48%;
      justify-content: space-between;
    }
    .inside-tariffs {
      background: #557ea1;
      color: white;
      padding: 1rem;
      border-left: 7px solid yellow;

      hr {
        border: 1px solid white;
      }
    }
    @media only screen and (max-width: 600px) {
      .tariffs_container {
        grid-template-columns: 100%;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    #howitworks .row{
      flex-direction: column;
      gap: 1rem;
    }
    .package_levels_container{
      position: inherit;
      bottom: auto;
    }
    .landing_stations_container{
      display: grid;
      grid-template-columns: 100%;
    }
  }
}
@media only screen and (max-width: 600px) {
  
  #missions{
    margin-top: 5rem;
  }
}

.trackpackage_page {
  .trackpackage_page_container {
    display: grid;
    grid-template-columns: 20% 80%;
    justify-content: space-between;
    gap: 1rem;
    .trackpackage_page_container__buttons {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }
}
#howitworks .row {
  gap: 1rem;
}


// Reports //

.reports_table{
  display: grid;
  grid-template-columns: 50% 50%;
  margin-bottom: 1rem;
  &__items{
    border: 1px solid rgb(255, 255, 255);
    padding: 1rem;
    background: green;
    color: white;
    
    div{
      font-size: 2rem;
    }
    &__package-price{
      background: rgb(3, 87, 165);
    }
    &__extra-price{
      background: rgb(105, 50, 71);
    }
    &__total-price{
      background: rgb(14, 175, 14);
    }
  }
  
}

.advanced_payments{
  height: 15rem;
  overflow-y:auto
}