$primary: #0f4c81;
$secondary: #814510;

@import "~bootswatch/dist/flatly/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/flatly/bootswatch";

/* Font start */
@font-face {
  font-family: "kanit";
  src: url("../../assets/fonts/kanit/Kanit-Regular.ttf");
}
@font-face {
  font-family: "Open Sans";
  src: url("../../assets/fonts/Open_Sans/OpenSans-Light.ttf");
}
@font-face {
  font-family: "Signika";
  src: url("../../assets/fonts/Signika/Signika-VariableFont_wght.ttf");
}

body {
  font-family: kanit, sans-serif !important;
}

.admin_container {
  text-transform: uppercase;
  button {
    text-transform: uppercase;
  }
}
/* Font finish */

/* Input start */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Input finish */

/* Button start */

/* Button finish */

/* Navbar start */
a.dropdown-toggle::after {
  display: none;
}

/* Navbar finish */

/* Toastify start */
.Toastify__toast--info {
  background: var(--info) !important;
}

.Toastify__toast--success {
  background: var(--success) !important;
}

.Toastify__toast--warning {
  background: var(--warning) !important;
}

.Toastify__toast--error {
  background: var(--danger) !important;
}

/* Toastify finish */

/* Spinner start */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: $secondary;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px $secondary, 0 0 5px $secondary;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: $secondary;
  border-left-color: $secondary;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Spinner finish */

/* Datatable start */
.rdt_TableHeader {
  display: none !important;
}

/* Datatable finish */

/* Checkout start */
.paid-text {
  position: relative;
}

.paid-text:before {
  margin: 3rem;
  content: "Ödənildi";
  position: absolute;
  font-size: 30px;
  transform: rotate(-30deg);
  -webkit-transform: rotate(-30deg);
  color: $secondary;
  top: 15%;
  left: 40%;
  z-index: 0;
}

/* Checkout finish */
